import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  changePasswordBegin,
  changePasswordSuccess,
  changePasswordErr,

} = actions;

const changePassword = (formData) => {
  return async (dispatch) => {
    try {
      await dispatch(changePasswordBegin());
      const query = await DataService.put('/user-master/updatepassword', formData);
      const token = getItem('access_token');
      const data = query.data
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(changePasswordSuccess(query.data.payload));
        dispatch(logOut())
        message.success(query.data.message)
      }
      else if (query.data !== null && query.data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(changePasswordSuccess(null));
          dispatch(logOut())
        }
        else {
          message.error(query.data.message)
          await dispatch(changePasswordSuccess(null));
        }
      } else {
        await dispatch(changePasswordSuccess(null));
      }
    } catch (err) {
      await dispatch(changePasswordErr(err));
    }
  };
};

export {
  changePassword,
};
