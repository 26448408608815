import { message } from 'antd';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import {
    readQuestionsDataBegin,
    readQuestionsDataSuccess,
    readQuestionsDataError,
} from './actions';
import { logOut } from '../authentication/actionCreator';


export const QuestionsDataRead = (type) => {
    return async (dispatch) => {
        try {
            let path = ""
            if (type == "Finshing School") {
                path = "/question-master/fs"
            }
            else if (type == "Academic") {
                path = "/question-master/academic"
            }
            await dispatch(readQuestionsDataBegin());
            const response = await DataService.get(path);
            const token = getItem('access_token');
            const data = response.data;
            if (token && data !== null && data.Error === false) {
                await dispatch(readQuestionsDataSuccess(data.payload));
            } else if (data !== null && data.Error === true) {
                if (Object.keys(data).length >= 3 && data.statusCode == 401) {
                    message.error(data.message)
                    await dispatch(readQuestionsDataSuccess(null));
                    dispatch(logOut())
                }
                else {
                    await dispatch(readQuestionsDataSuccess(null));
                }
            }
        } catch (err) {
            await dispatch(readQuestionsDataError(err));
        }
    };
};