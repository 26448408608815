import actions from './actions';

const {
  NOTICE_ADD_BEGIN,
  NOTICE_ADD_SUCCESS,
  NOTICE_ADD_ERR,

  NOTICE_READ_BEGIN,
  NOTICE_READ_SUCCESS,
  NOTICE_READ_ERR,

  NOTICE_UPDATE_BEGIN,
  NOTICE_UPDATE_SUCCESS,
  NOTICE_UPDATE_ERR,

  NOTICECOLLEGE_READ_BEGIN,
  NOTICECOLLEGE_READ_SUCCESS,
  NOTICECOLLEGE_READ_ERR,

  NOTICECOLLEGE_ADD_BEGIN,
  NOTICECOLLEGE_ADD_SUCCESS,
  NOTICECOLLEGE_ADD_ERR,
} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};


const initialGlobalNoticeState = {
  data: null,
  loading: false,
  error: null,
};

const initialNoticeReadState = {
  data: [],
  loading: false,
  error: null,
};

const updateStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const NOTICEReducer = (state = initialGlobalNoticeState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NOTICE_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTICE_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case NOTICE_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const NOTICECOLLEGEReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NOTICECOLLEGE_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTICECOLLEGE_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case NOTICECOLLEGE_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


const NOTICEAddReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NOTICE_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTICE_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case NOTICE_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const NOTICECOLLEGEAddReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NOTICECOLLEGE_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTICECOLLEGE_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case NOTICECOLLEGE_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const NOTICEUpdateReducer = (state = updateStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case NOTICE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case NOTICE_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case NOTICE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


export { NOTICEReducer, NOTICEUpdateReducer, NOTICEAddReducer,NOTICECOLLEGEReducer, NOTICECOLLEGEAddReducer  };
