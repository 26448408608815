const actions = {
  STUDENTS_ADD_BEGIN: 'STUDENTS_ADD_BEGIN',
  STUDENTS_ADD_SUCCESS: 'STUDENTS_ADD_SUCCESS',
  STUDENTS_ADD_ERR: 'STUDENTS_ADD_ERR',

  STUDENTS_READ_BEGIN: 'STUDENTS_READ_BEGIN',
  STUDENTS_READ_SUCCESS: 'STUDENTS_READ_SUCCESS',
  STUDENTS_READ_ERR: 'STUDENTS_READ_ERR',

  STUDENTS_UPDATE_BEGIN: 'STUDENTS_UPDATE_BEGIN',
  STUDENTS_UPDATE_SUCCESS: 'STUDENTS_UPDATE_SUCCESS',
  STUDENTS_UPDATE_ERR: 'STUDENTS_UPDATE_ERR',

  studentsAddBegin: () => {
    return {
      type: actions.STUDENTS_ADD_BEGIN,
    };
  },

  studentsAddSuccess: (data) => {
    return {
      type: actions.STUDENTS_ADD_SUCCESS,
      data,
    };
  },

  studentsAddErr: (err) => {
    return {
      type: actions.STUDENTS_ADD_ERR,
      err,
    };
  },

  studentsReadBegin: () => {
    return {
      type: actions.STUDENTS_READ_BEGIN,
    };
  },

  studentsReadSuccess: (data) => {
    return {
      type: actions.STUDENTS_READ_SUCCESS,
      data,
    };
  },

  studentsReadErr: (err) => {
    return {
      type: actions.STUDENTS_READ_ERR,
      err,
    };
  },

  studentsUpdateBegin: () => {
    return {
      type: actions.STUDENTS_UPDATE_BEGIN,
    };
  },

  studentsUpdateSuccess: (data) => {
    return {
      type: actions.STUDENTS_UPDATE_SUCCESS,
      data,
    };
  },

  studentsUpdateErr: (err) => {
    return {
      type: actions.STUDENTS_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
