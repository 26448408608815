import React from 'react'

export default function ListBlockWithTitle({ id, header, list }) {
    return (
        <div>
            <div id={id}>
                {header && <h2 className='header'>{header}</h2>}
                <ul className='unordered-list pt-0'>
                    {list.length !== 0 && list.map((item, itemIndex) => (
                        <li key={itemIndex} style={{textAlign:'justify'}}>
                            {item.title !== "" && <h4 className='d-inline'>{item.title}: </h4>}
                            {item.description}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
