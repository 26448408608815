import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../container/teacher/Dashboard';
import withTeacherLayout from '../../layout/withTeacherLayout';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import Profile from '../../container/teacher/Profile';
const NotFound = lazy(() => import('../../container/pages/404'));

const Teacher = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<Dashboard />} />           
        <Route path="/teacher-profile/*" element={<Profile/>} />                   
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withTeacherLayout(Teacher);
