import {
  UilUsersAlt,
  UilCalender,
  UilApps,
  UilMeetingBoard,
  UilQuestionCircle
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getMenuItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t('Dashboard')}
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilApps />
        </NavLink>
      ),
      null
    ),

    // getMenuItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/students`}>
    //     {t('Students')}
    //   </NavLink>,
    //   'students',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/students`}>
    //       <UilUsersAlt />
    //     </NavLink>
    //   ),
    //   null,
    // ),
    getMenuItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Academic')}</NavTitle>,
      'Academic-title',
      null,
      null,
      'group',
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/academic-questions`}>
        Questions
      </NavLink>,
      'academic-questions',
      !topMenu &&
      <NavLink className="menuItem-iocn" to={`${path}/academic-questions`}>
        <UilQuestionCircle />
      </NavLink>,
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/academic-schedules`}>
        Schedules
      </NavLink>,
      'academic-schedules',
      !topMenu &&
      <NavLink className="menuItem-iocn" to={`${path}/academic-schedules`}>
        <UilCalender />
      </NavLink>,
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/academic-interviews`}>
        {t('Interview Results')}
      </NavLink>,
      'academic-interviews',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/academic-interviews`}>
          <UilMeetingBoard />
        </NavLink>
      ),
      null,
    ),

    getMenuItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('Finishing School')}</NavTitle>,
      'fs-title',
      null,
      null,
      'group',
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/questions`}>
        Questions
      </NavLink>,
      'questions',
      !topMenu &&
      <NavLink className="menuItem-iocn" to={`${path}/questions`}>
        <UilQuestionCircle />
      </NavLink>,
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/schedules`}>
        Schedules
      </NavLink>,
      'schedules',
      !topMenu &&
      <NavLink className="menuItem-iocn" to={`${path}/schedules`}>
        <UilCalender />
      </NavLink>,
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
        {t('Interview Results')}
      </NavLink>,
      'interviews',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/interviews`}>
          <UilMeetingBoard />
        </NavLink>
      ),
      null,
    ),

  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
