// import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { AuthReducer, ForgotPasswordReducer, VerifyOTPReducer, ResetPassReducer, MessageReducer, CollegeWiseMessageReducer } from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';

import { getScheduledDataReducer, publishResultsReducer } from './schedule/reducers';
import { ProgressReducer } from './teacherDashboard/reducers';
import { PdfFileReducer } from './pdf/reducers';
import { ChangePasswordReducer } from './changepassword/reducers';
import { getLeaderboardReducer } from './leaderboard/reducers';
import LayoutChangeReducer from './themeLayout/reducers';
import { STUDENTSReducer, STUDENTSUpdateReducer, STUDENTSAddReducer } from './students/reducers';
import { NOTICEReducer, NOTICEAddReducer, NOTICEUpdateReducer, NOTICECOLLEGEReducer, NOTICECOLLEGEAddReducer } from './notice/reducers';
import { USERSPWDCHANGEUpdateReducer } from './UsersPasswordChange/reducers';

import { getInterviewAttemptsReducer, getInterviewsByScheduleReducer } from './interview/reducers'

import { SyncDataReducer, SyncFsPasswordsReducer } from './sync/reducers'
import { ReadQuestionsDataReducer } from './questions/reducers'

const rootReducers = combineReducers({
  auth: AuthReducer,
  ForgotPass: ForgotPasswordReducer,
  VerifyOTP: VerifyOTPReducer,
  ResetPassRes: ResetPassReducer,
  ChangeLayoutMode,
  ChangePassword: ChangePasswordReducer,
  msg: MessageReducer,
  Layout: LayoutChangeReducer,

  // Admin reducers
  allquestions: ReadQuestionsDataReducer,

  Students: STUDENTSReducer,
  StudentsUpdateRes: STUDENTSUpdateReducer,
  StudentsAddRes: STUDENTSAddReducer,

  Notice: NOTICEReducer,
  NoticeUpdateRes: NOTICEUpdateReducer,
  NoticeAddRes: NOTICEAddReducer,
  NoticeCollegeAddRes: NOTICECOLLEGEAddReducer,
  NoticeCollege: NOTICECOLLEGEReducer,

  UsersPwdChange: USERSPWDCHANGEUpdateReducer,
  interviewAttempts: getInterviewAttemptsReducer,


  SyncDataRes: SyncDataReducer,
  SyncFsPasswordsRes: SyncFsPasswordsReducer,

  // teacher reducers

  ProgressData: ProgressReducer,  

  TopicScheduledData: getScheduledDataReducer,
  publishResultsRes: publishResultsReducer,


  // admin reducers

  interviewsBySchedule: getInterviewsByScheduleReducer,


  // student reducers

  collegeWiseNotce: CollegeWiseMessageReducer,

  PdfFile: PdfFileReducer,

  leaderboardResults: getLeaderboardReducer,
});

export default rootReducers;
