const actions = {
  GET_INTERVIEW_BY_SCHEDULE_BEGIN: 'GET_INTERVIEW_BY_SCHEDULE_BEGIN',
  GET_INTERVIEW_BY_SCHEDULE_SUCCESS: 'GET_INTERVIEW_BY_SCHEDULE_SUCCESS',
  GET_INTERVIEW_BY_SCHEDULE_ERR: 'GET_INTERVIEW_BY_SCHEDULE_ERR',

  GET_INTERVIEW_ATTEMPTS_BEGIN: 'GET_INTERVIEW_ATTEMPTS_BEGIN',
  GET_INTERVIEW_ATTEMPTS_SUCCESS: 'GET_INTERVIEW_ATTEMPTS_SUCCESS',
  GET_INTERVIEW_ATTEMPTS_ERR: 'GET_INTERVIEW_ATTEMPTS_ERR',


  getInterviewByScheduleBegin: () => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_BEGIN,
    };
  },

  getInterviewByScheduleSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_SUCCESS,
      data,
    };
  },

  getInterviewByScheduleErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_ERR,
      err,
    };
  },

  getInterviewAttemptsBegin: () => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_BEGIN,
    };
  },

  getInterviewAttemptsSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_SUCCESS,
      data,
    };
  },

  getInterviewAttemptsErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_ERR,
      err,
    };
  },

};

export default actions;
