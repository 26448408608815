import React from 'react'
import ListBlockWithTitle from './ListBlockWithTitle'

export default function SubListBlock({ data }) {
    console.log(data)
    return (
        <div>
            <div>
                {data.length !== 0 && data.map((section, sectionIndex) => (
                    <div className='ml-20 mb-20' key={sectionIndex}>
                        <h4>{sectionIndex + 1}. {section.title}:</h4>
                        <ListBlockWithTitle
                            id={section.title}
                            header={null}
                            list={section.list}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
