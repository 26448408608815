import actions from './actions';

const {
  SYNC_FS_PASSWORDS_BEGIN,
  SYNC_FS_PASSWORDS_SUCCESS,
  SYNC_FS_PASSWORDS_ERR,

  GET_SYNC_DATA_BEGIN,
  GET_SYNC_DATA_SUCCESS,
  GET_SYNC_DATA_ERR,

} = actions;

const syncDataInitialState = {
  data: [],
  loading: false,
  error: null,
};

const syncFsPasswordsInitialState = {
  data: null,
  loading: false,
  error: null,
};


const SyncDataReducer = (state = syncDataInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SYNC_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_SYNC_DATA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_SYNC_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const SyncFsPasswordsReducer = (state = syncFsPasswordsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case SYNC_FS_PASSWORDS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SYNC_FS_PASSWORDS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SYNC_FS_PASSWORDS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};


export { SyncDataReducer, SyncFsPasswordsReducer };
