import React from 'react'

export default function Block({ id, header, content, subHeaders }) {
    return (
        <div>
            <div id={id}>
                {header && <h2 className='header'>{header}</h2>}
                {content && <p className='content'>{content}</p>}
                {subHeaders && subHeaders.length !== 0 && subHeaders.map((subHeader, index) => (
                    <div key={index}>
                        <h2 className='sub-header'>{subHeader.title}</h2>
                        <p className='content'>{subHeader.text}</p>
                        {subHeader.list && (
                            <ul className='unordered-list'>
                                {subHeader.list.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
