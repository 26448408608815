
import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import Interviews from '../../container/admin/Interviews';
import Schedules from '../../container/admin/Schedules';
import Sync from '../../container/admin/Sync';
import { getItem } from '../../utility/localStorageControl';
import Questions from '../../container/admin/Questions';

const DashboardComponent = lazy(() => import('../../container/admin/Dashboard'));
const StudentComponent = lazy(() => import('../../container/admin/Students'));
const NoticeComponent = lazy(() => import('../../container/admin/Notice'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const username = getItem('username')
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>

        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route index path="/dashboard" element={<DashboardComponent />} />                
        <Route path="/students" element={<StudentComponent />} />                                
        <Route path="/schedules" element={<Schedules type={'Finshing School'} />} />
        <Route path="/academic-schedules" element={<Schedules type={'Academic'} />} />
        <Route path="/interviews" element={<Interviews type={'Finshing School'} />} />
        <Route path="/academic-interviews" element={<Interviews type={'Academic'} />} />
        <Route path="/questions" element={<Questions type={'Finshing School'} />} />
        <Route path="/academic-questions" element={<Questions type={'Academic'} />} />        
        <Route path="/notice" element={<NoticeComponent />} />
        <Route path="/syncpasswords" element={<Sync />} />        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);