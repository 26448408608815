import React from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

function Dashboard() {

    const dispatch = useDispatch();

    useEffect(() => {
        
    }, [dispatch])

    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title="Dashboard" />
                                               
            </Main>
        </div >
    );
}

export default Dashboard;