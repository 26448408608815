import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from '../../container/student/Dashboard';
import withStudentLayout from '../../layout/withStudentLayout';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import Profile from '../../container/student/Profile';

const NotFound = lazy(() => import('../../container/pages/404'));

const Student = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<Dashboard />} />
        <Route path="/student-profile/*" element={<Profile/>} />                
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withStudentLayout(Student);
