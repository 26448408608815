import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
import { useSelector } from 'react-redux';
import AppLogo from '../layout/AppLogo';

const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
// const NotFound = lazy(() => import('../container/pages/404'));

const AuthRoot = () => {
  const navigate = useNavigate();

  useEffect(() => navigate('/'));
};


const FrontendRoutes = React.memo(() => {
  const { mode } = useSelector((state) => {
    return {
      mode: state.Layout.mode
    }
  });
  return (
    <div>
      <div className="ninjadash-authentication-brand">
        {/* <img src={require(`../../../static/img/icon/tesseract-learning-logo.png`)} alt="" /> */}
        <AppLogo width={320}/>
      </div>
      <Routes>
        <Route index element={<Login />} />
        <Route path="forgotPassword" element={<ForgotPass />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="*" element={<AuthRoot />} />
      </Routes>
    </div>
  );
});

export default AuthLayout(FrontendRoutes);
