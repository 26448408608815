import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilBell from '@iconscout/react-unicons/icons/uil-bell';
import UilFileAlt from '@iconscout/react-unicons/icons/uil-file-alt';
import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilMoon from '@iconscout/react-unicons/icons/uil-moon';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilExternalLinkAlt from '@iconscout/react-unicons/icons/uil-external-link-alt';
import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import UilWrench from '@iconscout/react-unicons/icons/uil-wrench';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './Message';
import Notification from './Notification';
import Search from './Search';
import Settings from './settings';
import { logOut } from '../../../redux/authentication/actionCreator';

import { Dropdown } from '../../dropdown/dropdown';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { getItem, setItem } from '../../../utility/localStorageControl';
import { changeLayoutMode } from '../../../redux/themeLayout/actionCreator';
import { useEffect } from 'react';
import { getNgrokURL } from '../../../redux/schedule/actionCreator';

const TeacherAuthInfo = React.memo(() => {
  const dispatch = useDispatch();

  const LayoutMode = getItem('mode')

  const [state, setState] = useState({
    flag: 'en',
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();

    dispatch(logOut(() => navigate('/')));
  };

  // useEffect(() => {
  //   dispatch(getNgrokURL())
  // }, [])

  const { currentWeek, currentWeekLoading, ngrokURL, ngrokURLLoading } = useSelector((state) => {
    return {
      currentWeek: state.currentWeek.data,
      currentWeekLoading: state.currentWeek.loading,
      ngrokURL: state.NgrokUrl.data,
      ngrokURLLoading: state.NgrokUrl.loading,
    }
  })

  const getNumberFromString = (weekname) => {
    const arr = weekname.split('-')
    return arr[1];
  }


  useEffect(() => {
    if (LayoutMode === 'darkMode') {
      document.body.classList.remove('light-mode');
      document.body.classList.add('dark-mode');
    }
    else if (LayoutMode === 'lightMode') {
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    }
  }, [LayoutMode])

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };

  const switchMode = () => {
    if (LayoutMode === 'lightMode') {
      setItem('mode', 'darkMode')
      changeLayout('darkMode')
      document.body.classList.remove('light-mode');
      document.body.classList.add('dark-mode');
    }
    else if (LayoutMode === 'darkMode') {
      setItem('mode', 'lightMode')
      changeLayoutMode('lightMode')
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    }
  }

  const profilename = getItem('profilename')
  const collegeName = getItem('collegeName')

  const name = profilename ? profilename : 'Unknown'

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h4">{name[0].toUpperCase() + name.slice(1).toLowerCase()}</Heading>
            <p>{collegeName}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="teacher-profile">
              <UilUser /> Profile
            </Link>
          </li>
          {
            ngrokURL !== null &&
            <li>
              <Link target='_blank' to={ngrokURL.ngrokurl}>
                <UilExternalLinkAlt /> Tesselator
              </Link>
            </li>
          }
          {/* <li>
            <Link onClick={switchMode}>
              <UilMoon /> Switch Mode
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <UilUsersAlt /> Activity
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <UilFileAlt /> My Reports
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <UilBell /> Help
            </Link>
          </li> */}
          {/* <li>
            <Link to="change-password">
              <UilWrench /> Password
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('esp', e)} to="#">
        <img src={require('../../../static/img/flag/esp.png')} alt="" />
        <span>Spanish</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('ar', e)} to="#">
        <img src={require('../../../static/img/flag/ar.png')} alt="" />
        <span>Arabic</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <div style={{ color: '#2D5D85', fontSize: '13px', textAlign: 'right', backgroundColor: '#fcefeb', paddingLeft: '10px', paddingRight: '10px', marginRight: '10px' }}>
        Please note all data created is temporary and some features may not be available yet.
      </div> */}
      <div className='fontsize-14' style={{ fontWeight: 500, textAlign: 'right', paddingLeft: '10px', paddingRight: '10px', marginRight: '10px' }}>
        {
          currentWeek !== null &&
          <span>Current : <span style={{ color: '#2D5D85' }}>{currentWeek.name}</span></span>
        }
      </div>
      {/* <div>Week-2</div> */}
      {/* <Search /> */}
      {/* <Message />
      <Notification />
      <Settings /> */}
      {/* <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar>{name[0].toUpperCase()}</Avatar>
            <span className="ninjadash-nav-actions__author--name">{name[0].toUpperCase() + name.slice(1).toLowerCase()}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default TeacherAuthInfo;
