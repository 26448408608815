const actions = {
  GET_SYNC_DATA_BEGIN: 'GET_SYNC_DATA_BEGIN',
  GET_SYNC_DATA_SUCCESS: 'GET_SYNC_DATA_SUCCESS',
  GET_SYNC_DATA_ERR: 'GET_SYNC_DATA_ERR',

  SYNC_FS_PASSWORDS_BEGIN: 'SYNC_FS_PASSWORDS_BEGIN',
  SYNC_FS_PASSWORDS_SUCCESS: 'SYNC_FS_PASSWORDS_SUCCESS',
  SYNC_FS_PASSWORDS_ERR: 'SYNC_FS_PASSWORDS_ERR',

  getSyncDataBegin: () => {
    return {
      type: actions.GET_SYNC_DATA_BEGIN,
    };
  },

  getSyncDataSuccess: (data) => {
    return {
      type: actions.GET_SYNC_DATA_SUCCESS,
      data,
    };
  },

  getSyncDataErr: (err) => {
    return {
      type: actions.GET_SYNC_DATA_ERR,
      err,
    };
  },

  syncFsPasswordsBegin: () => {
    return {
      type: actions.SYNC_FS_PASSWORDS_BEGIN,
    };
  },

  syncFsPasswordsSuccess: (data) => {
    return {
      type: actions.SYNC_FS_PASSWORDS_SUCCESS,
      data,
    };
  },

  syncFsPasswordsErr: (err) => {
    return {
      type: actions.SYNC_FS_PASSWORDS_ERR,
      err,
    };
  },
};

export default actions;
