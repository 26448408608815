import actions from './actions';

const {

  GET_INTERVIEW_BY_SCHEDULE_BEGIN,
  GET_INTERVIEW_BY_SCHEDULE_SUCCESS,
  GET_INTERVIEW_BY_SCHEDULE_ERR,

  GET_INTERVIEW_ATTEMPTS_BEGIN,
  GET_INTERVIEW_ATTEMPTS_SUCCESS,
  GET_INTERVIEW_ATTEMPTS_ERR,

} = actions;

const interviewQuestionsInitialState = {
  data: null,
  loading: false,
  error: null,
};

const saveInitialState = {
  data: null,
  loading: false,
  error: null,
}

const skipInitialState = {
  data: null,
  loading: false,
  error: null,
}

const skipandsubmitInitialState = {
  data: null,
  loading: false,
  error: null,
}

const submitInitialState = {
  data: null,
  loading: false,
  error: null,
}

const InterviewsByTopicInitialState = {
  data: [],
  loading: false,
  error: null,
}

const InterviewsByScheduleInitialState = {
  data: [],
  loading: false,
  error: null,
}

const interviewAttemptsInitialState = {
  data: [],
  loading: false,
  error: null,
}

const quitTimeoutInitialState = {
  data: null,
  loading: false,
  error: null,
}

const studentInterviewsInitialState = {
  data: [],
  loading: false,
  error: null,
}

const getInterviewsByScheduleReducer = (state = InterviewsByScheduleInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEW_BY_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_BY_SCHEDULE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_BY_SCHEDULE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getInterviewAttemptsReducer = (state = interviewAttemptsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEW_ATTEMPTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_ATTEMPTS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_ATTEMPTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


export { getInterviewAttemptsReducer, getInterviewsByScheduleReducer };
