import actions from './actions';

const {

  GET_SCHEDULED_DATA_BEGIN,
  GET_SCHEDULED_DATA_SUCCESS,
  GET_SCHEDULED_DATA_ERR,

  PUBLISH_RESULTS_BEGIN,
  PUBLISH_RESULTS_SUCCESS,
  PUBLISH_RESULTS_ERR

} = actions;

const getSheduledDataInitialState = {
  data: null,
  loading: false,
  error: null,
};

const publishInitialState = {
  data: null,
  loading: false,
  error: null,
};


const getScheduledDataReducer = (state = getSheduledDataInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SCHEDULED_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHEDULED_DATA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_SCHEDULED_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const publishResultsReducer = (state = publishInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PUBLISH_RESULTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case PUBLISH_RESULTS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case PUBLISH_RESULTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


export { getScheduledDataReducer, publishResultsReducer };
