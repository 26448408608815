import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { TopMenuStyle } from './Style';


function StudentMenu() {
  const path = '/student';

  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <ul>
          <li className="">
            <NavLink to={`${path}`} >
              Dashboard
            </NavLink>
          </li>
          {/* <li className="">
            <NavLink to={`${path}/subjects`} >
              Subjects
            </NavLink>
          </li> */}
          {/* <li className="">
            <NavLink to={`${path}/performance`} >
              Performance
            </NavLink>
          </li> */}
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default StudentMenu;