import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  
  getScheduledDataBegin,
  getScheduledDataSuccess,
  getScheduledDataErr,

  publishResultsBegin,
  publishResultsSuccess,
  publishResultsErr

} = actions;

// in use
const getScheduledData = (type) => {
  return async (dispatch) => {
    try {
      await dispatch(getScheduledDataBegin());
      let path = ""
      if (type == "Finshing School") {
        path = "/topic-schedule/fsschedules"
      }
      else if (type == "Academic") {
        path = "/topic-schedule/academicschedules"
      }
      const query = await DataService.get(path);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getScheduledDataSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getScheduledDataSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getScheduledDataSuccess(null));
        }
      }
      else {
        await dispatch(getScheduledDataSuccess(null));
      }
    } catch (err) {
      await dispatch(getScheduledDataErr(err));
    }
  };
};

const publishInterviewResults = (type, scheduleId) => {
  return async (dispatch) => {
    try {
      await dispatch(publishResultsBegin());
      let path = ""
      if (type == "Finshing School") {
        path = `/topic-schedule/publishfsschedules/${scheduleId}`
      }
      else if (type == "Academic") {
        path = `/topic-schedule/publishacademicschedules/${scheduleId}`
      }
      const query = await DataService.get(path);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(publishResultsSuccess(data));
        message.success(data.message)
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(publishResultsSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(publishResultsSuccess(null));
        }
      }
      else {
        await dispatch(publishResultsSuccess(null));
      }
    } catch (err) {
      await dispatch(publishResultsErr(err));
    }
  };
};


export {  
  getScheduledData,
  publishInterviewResults
};
