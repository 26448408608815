export const READ_QUESTIONS_BEGIN = 'READ_QUESTIONS_BEGIN';
export const READ_QUESTIONS_SUCCESS = 'READ_QUESTIONS_SUCCESS';
export const READ_QUESTIONS_ERROR = 'READ_QUESTIONS_ERROR';

export const readQuestionsDataBegin = () => ({
    type: READ_QUESTIONS_BEGIN,
});

export const readQuestionsDataSuccess = (data) => ({
    type: READ_QUESTIONS_SUCCESS,
    data,
});

export const readQuestionsDataError = (err) => ({
    type: READ_QUESTIONS_ERROR,
    err,
});