import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const {
  getSyncDataBegin,
  getSyncDataSuccess,
  getSyncDataErr,

  syncFsPasswordsBegin,
  syncFsPasswordsSuccess,
  syncFsPasswordsErr,
} = actions;

const GetSyncData = () => {
  return async (dispatch) => {
    try {
      await dispatch(getSyncDataBegin());
      const response = await DataService.get('/sync-fs-passwords');
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(getSyncDataSuccess(response.data.payload));        
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          message.error(response.data.message);
          dispatch(logOut());
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(getSyncDataErr(err));
      message.error(err);
    }
  };
};

const syncFSPasswords = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(syncFsPasswordsBegin());
      const query = await DataService.post(`/sync-fs-passwords/syncpasswords`, data);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(syncFsPasswordsSuccess(query.data));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {
          message.error(query.data.message);
          dispatch(logOut());
        }
        throw query.data.message;
      }
    } catch (err) {
      await dispatch(syncFsPasswordsErr(err));
      message.error(err);
    }
  };
};

export {
  GetSyncData,
  syncFSPasswords
};
