import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
// import {
//   UilBooks,
//   UilBook,
// } from '@iconscout/react-unicons';
import { TopMenuStyle } from './Style';
// import { ellipsis } from '../utility/utility'
import { useState } from 'react';
import { useRef } from 'react';
import { removeItem } from '../utility/localStorageControl';
import { Tooltip } from 'antd';


function TeacherMenu() {
  const path = '/teacher';

  const dropdownRef = useRef(null);

  const [megamenuVisible, setMegamenuViible] = useState(false)

  const addParentActive = (event) => {
    removeItem('activeunit')
    removeItem('activestudenttopic')
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMegamenuViible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <ul>          
          <li className="">
            <NavLink to={`${path}`} >
              Subjects
            </NavLink>
          </li>          
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TeacherMenu;