import React, { Suspense, useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { useDispatch, useSelector } from 'react-redux';

function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {    
  }, [dispatch])

  return (
    <>
      <Main>
        <PageHeader className="ninjadash-page-header-main" title="Dashboard" />        
      </Main>
    </>
  );
}
export default Dashboard;