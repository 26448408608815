import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { getItem } from '../../../utility/localStorageControl';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const SignOut = (e) => {
    e.preventDefault();

    dispatch(logOut(() => navigate('/')));
  };

  const username = getItem('profilename')
  const collegeName = getItem('collegeName')  
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">{username ? (username[0].toUpperCase() + username.slice(1).toLowerCase()) : 'Unknown'}</Heading>
            <p>{collegeName}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">         
          {/* <li>
            <Link to="change-password">
              <UilWrench /> Password
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>      
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar icon={<UilUser />} />
            <span className="ninjadash-nav-actions__author--name">{username ? (username[0].toUpperCase() + username.slice(1).toLowerCase()) : 'Unknown'}</span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
