const actions = {

  GET_SCHEDULED_DATA_BEGIN: 'GET_SCHEDULED_DATA_BEGIN',
  GET_SCHEDULED_DATA_SUCCESS: 'GET_SCHEDULED_DATA_SUCCESS',
  GET_SCHEDULED_DATA_ERR: 'GET_SCHEDULED_DATA_ERR',

  PUBLISH_RESULTS_BEGIN: 'PUBLISH_RESULTS_BEGIN',
  PUBLISH_RESULTS_SUCCESS: 'PUBLISH_RESULTS_SUCCESS',
  PUBLISH_RESULTS_ERR: 'PUBLISH_RESULTS_ERR',


  getScheduledDataBegin: () => {
    return {
      type: actions.GET_SCHEDULED_DATA_BEGIN,
    };
  },

  getScheduledDataSuccess: (data) => {
    return {
      type: actions.GET_SCHEDULED_DATA_SUCCESS,
      data,
    };
  },

  getScheduledDataErr: (err) => {
    return {
      type: actions.GET_SCHEDULED_DATA_ERR,
      err,
    };
  },

  publishResultsBegin: () => {
    return {
      type: actions.PUBLISH_RESULTS_BEGIN,
    };
  },

  publishResultsSuccess: (data) => {
    return {
      type: actions.PUBLISH_RESULTS_SUCCESS,
      data,
    };
  },

  publishResultsErr: (err) => {
    return {
      type: actions.PUBLISH_RESULTS_ERR,
      err,
    };
  },

};

export default actions;
