import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {
  getInterviewByScheduleBegin,
  getInterviewByScheduleSuccess,
  getInterviewByScheduleErr,

  getInterviewAttemptsBegin,
  getInterviewAttemptsSuccess,
  getInterviewAttemptsErr,
} = actions;


const getInterviewsBySchedule = (type, scheduleName) => {
  return async (dispatch) => {
    try {
      let path = ""
      if (type == "Finshing School") {
        path = `/interview-master/scheduledfsinterviews/${scheduleName}`
      }
      else if (type == "Academic") {
        path = `/interview-master/scheduledacademicinterviews/${scheduleName}`
      }
      await dispatch(getInterviewByScheduleBegin());
      const response = await DataService.get(path);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewByScheduleSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewByScheduleSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewByScheduleSuccess([]));
        }
      }
      else {
        await dispatch(getInterviewByScheduleSuccess([]));
      }
    } catch (err) {
      await dispatch(getInterviewByScheduleErr(err));
    }
  };
};

const getInterviewAttemptsById = (type, interviewId) => {
  return async (dispatch) => {
    try {
      let path = ""
      if (type == "Finshing School") {
        path = `/interview-attempts/findbyfsinterviewMr/${interviewId}`
      }
      else if (type == "Academic") {
        path = `/interview-attempts/findbyacademicinterviewMr/${interviewId}`
      }
      await dispatch(getInterviewAttemptsBegin());
      const response = await DataService.get(path);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewAttemptsSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewAttemptsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewAttemptsSuccess([]));
        }
      }
      else {
        await dispatch(getInterviewAttemptsSuccess([]));
      }
    } catch (err) {
      await dispatch(getInterviewAttemptsErr(err));
    }
  };
};


export {
  getInterviewAttemptsById,
  getInterviewsBySchedule,
};
