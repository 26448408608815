import React from 'react'
import { Main } from './container/styled'
import { ErrorWrapper } from './container/pages/style'
import Heading from './components/heading/heading'

export default function OfflinePage() {
    return (
        <div>
            <Main>
                <ErrorWrapper>
                    <img src={require('./static/img/pages/undraw_server_down_s-4-lk.svg').default} alt='INTERNET_DISCONNECTED' />
                    <Heading className="error-text-style2" as="h4">
                        You are offline!
                    </Heading>
                    <p>Please check your internet connection</p>
                </ErrorWrapper>                                
            </Main>
        </div>
    )
}
