import React, { useEffect, useState } from 'react'
import { Main, TableDefaultStyle } from '../styled';
import { TopSellerWrap } from './styles/dashboardStyle';
import { Table } from 'antd';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../components/page-headers/page-headers';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { Cards } from '../../components/cards/frame/cards-frame';
import { QuestionsDataRead } from '../../redux/questions/actionCreator';

function Questions({ type }) {

    const dispatch = useDispatch()
    const [TableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const { allQuestions, allQuestionsLoading } = useSelector((state) => {
        return {
            allQuestions: state.allquestions.data,
            allQuestionsLoading: state.allquestions.loading,
        }
    })

    useEffect(() => {
        dispatch(QuestionsDataRead(type))
    }, [type])

    useEffect(() => {
        if (allQuestions) {
            const filteredData = allQuestions.map((item, index) => ({
                id: item.id,
                key: index + 1,
                sno: index + 1,
                question: item.question.replace(/<[^>]*>|&nbsp;/g, ' '),
                questionWithHtml: item.question,
                difficultylevel: item.difficultylevel,
                questionType: item.questionType,
                expectedConceptualKnowledge: item.expectedConceptualKnowledge,
                statuss: <span className={`status ${item.status.toLowerCase()}`}>{item.status}</span>,
                status: item.status,
                program: item.program,
                modifiedProgram: item.modifiedProgram,
                createdon: new Date(item.createdon).toLocaleDateString(),
                updatedon: new Date(item.updatedon).toLocaleDateString(),
                updatedby: item.updatedby
            }));
            setTableData(filteredData);
            setFilteredData(filteredData);
        }
    }, [allQuestions])

    const columns = [
        {
            title: 'S.No',
            key: 'sno',
            dataIndex: 'sno',
            align: 'center',
            width: 60
        },
        {
            title: 'Question',
            key: 'question',
            dataIndex: 'question',
            ellipsis: true,
            width: 420,
        },
        {
            title: 'Type',
            key: 'questionType',
            dataIndex: 'questionType',
            width: 80,
            filters: TableData.length !== 0 && Array.from(new Set(TableData.map(question => question.questionType))).map(questionType => ({ key: questionType, text: questionType, value: questionType })),
            onFilter: (value, record) => record.questionType === value,
        },
        {
            title: 'Status',
            dataIndex: 'statuss',
            width: '30%',
            align: 'center',
            width: 100,
            filters: TableData.length !== 0 && Array.from(new Set(TableData.map(question => question.status))).map(status => ({ key: status, text: status, value: status })),
            onFilter: (value, record) => record.status === value,
        }
    ]

    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title={`${type} Questions`} />
                {allQuestionsLoading ?
                    (
                        <div className="spin">
                            <CustomSpinner Message={'Loading please wait...'} />
                        </div>
                    ) : (
                        <Cards headless>
                            <TableDefaultStyle className="ninjadash-having-header-bg">
                                <TopSellerWrap>
                                    <div className="table-bordered top-seller-table table-responsive">
                                        <Table
                                            columns={columns}
                                            dataSource={filteredData}
                                            loading={allQuestionsLoading}
                                            pagination={{
                                                className: 'd-flex align-center-v',
                                                size: 'small',
                                                responsive: true,
                                                showTotal: (total) => `Total ${total} Questions`
                                            }}
                                            expandable={{
                                                expandedRowRender: (record) =>
                                                    <div>
                                                        <div className='d-flex justify-content-around mb-10'>
                                                            <span>Created on: {record.createdon}</span>
                                                            <span>Last updated: {record.updatedon}</span>
                                                            <span>Updated By: {record.updatedby}</span>
                                                        </div>
                                                        {
                                                            record.questionType == "CODE" ?
                                                                <>
                                                                    <h4 className='text-left'>Question</h4>
                                                                    {
                                                                        record.question.split('\n').map((line, index) => {
                                                                            if (line.trim() !== '') {
                                                                                return (
                                                                                    <li
                                                                                        key={index}
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                        }}
                                                                                    >
                                                                                        <span>{line}</span>
                                                                                    </li>
                                                                                );
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        })
                                                                    }
                                                                </>
                                                                :
                                                                <div dangerouslySetInnerHTML={{ __html: 'Q. ' + record.question }} className='pl-20 d-flex justify-content-start' />
                                                        }
                                                        <div className='d-block pl-20'>
                                                            {
                                                                (record.questionType !== "THEORY" && record.questionType !== "VIVA" && record.questionType !== "CODE") &&
                                                                <div className="page-panel">
                                                                    <PanelGroup direction="horizontal">
                                                                        <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                                            {
                                                                                (record.questionType != "PREDICT OUTPUT" || record.questionType != "EXPLAIN CODE") ?
                                                                                    <>
                                                                                        <h4 className='text-left d-flex'>Program: </h4>
                                                                                        {
                                                                                            record.program && record.program.split('\n').map((line, index) => {
                                                                                                if (line.trim() !== '') {
                                                                                                    return (
                                                                                                        <li
                                                                                                            key={index}
                                                                                                            style={{
                                                                                                                display: 'flex',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span>{line}</span>
                                                                                                        </li>
                                                                                                    );
                                                                                                } else {
                                                                                                    return null;
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    (record.questionType == "PREDICT OUTPUT" || record.questionType == "EXPLAIN CODE") ?
                                                                                        <>
                                                                                            <h4 className='text-left d-flex'>Program: </h4>
                                                                                            {
                                                                                                record.modifiedProgram && record.modifiedProgram.split('\n').map((line, index) => {
                                                                                                    if (line.trim() !== '') {
                                                                                                        return (
                                                                                                            <li
                                                                                                                key={index}
                                                                                                                style={{
                                                                                                                    display: 'flex',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span>{line}</span>
                                                                                                            </li>
                                                                                                        );
                                                                                                    } else {
                                                                                                        return null;
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <div className='d-flex'>
                                                                                            NA
                                                                                        </div>
                                                                            }
                                                                        </Panel>
                                                                        <PanelResizeHandle style={{ width: '1px', backgroundColor: '#ddd' }} />
                                                                        <Panel key="right" collapsible={false} collapsedSize={10} defaultSize={34} minSize={10} maxSize={70}>
                                                                            <div className='d-block' style={{ paddingLeft: '10px' }}>
                                                                                <h4 className='text-left d-flex'>{record.questionType} </h4>
                                                                                {
                                                                                    record.modifiedProgram ? record.modifiedProgram.split('\n').map((line, index) => {
                                                                                        if (line.trim() !== '') {
                                                                                            return (
                                                                                                <li
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>{line}</span>
                                                                                                </li>
                                                                                            );
                                                                                        } else {
                                                                                            return null;
                                                                                        }
                                                                                    })
                                                                                        :
                                                                                        <div className='d-flex'>
                                                                                            NA
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </Panel>
                                                                    </PanelGroup>
                                                                </div>
                                                            }

                                                        </div>

                                                    </div>
                                            }}
                                        />
                                    </div>
                                </TopSellerWrap>
                            </TableDefaultStyle>
                        </Cards>
                    )
                }
            </Main>
        </div>
    )
}

export default Questions