import React, { useEffect, useState } from 'react'
import { TableWrapper, Main } from '../styled';
import { Col, Row, Table, Button, Modal, Form, Select, message } from 'antd';
import { ProjectList } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { GetSyncData, syncFSPasswords } from '../../redux/sync/actionCreator';
import actions from '../../redux/sync/actions';

const { syncFsPasswordsSuccess } = actions

const { Option } = Select;

export default function Sync() {
    const dispatch = useDispatch()
    const { syncData, syncDataLoading, sync, isSyncing } = useSelector((state) => {
        return {
            syncData: state.SyncDataRes.data,
            syncDataLoading: state.SyncDataRes.loading,
            sync: state.SyncFsPasswordsRes.data,
            isSyncing: state.SyncFsPasswordsRes.loading
        }
    })

    const [form] = Form.useForm();
    const [isModalVisible, setModalVisible] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    useEffect(() => {
        dispatch(GetSyncData())
        dispatch(syncFsPasswordsSuccess(null))
    }, [dispatch])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Action',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Last Sync',
            dataIndex: 'lastsync',
            key: 'lastsync',
        },
        {
            title: '..',
            render: (text, record) => (
                <div className='d-flex'>
                    <Button
                        key="sync_password_from_fs"
                        onClick={() => { OpenModal(record) }}
                        type="primary"
                        to="#"
                        shape="round"
                        loading={isSyncing || record.syncing}
                    >
                        {(isSyncing || record.syncing) ? 'Syncing' : 'Sync'}
                    </Button>
                </div>
            ),
        }
    ]

    const OpenModal = (record) => {
        setModalVisible(true)
        setSelectedData(record)
    }

    const CloseModal = () => {
        setModalVisible(false)
        setSelectedData(null)
        form.resetFields()
    }

    const SyncNow = () => {
        form.validateFields()
            .then((values) => {
                const syncData = {
                    id: parseInt(selectedData.id),
                    days: parseInt(values.days)
                };
                setModalVisible(false);
                setSelectedData(null)
                form.resetFields()
                dispatch(syncFSPasswords(syncData));
            })
            .catch(e => {
                message.error("Failed to sync!")
            })
    }

    useEffect(() => {
        if (sync && sync.Error == false) {
            message.success(sync.message)
            dispatch(GetSyncData())
            form.resetFields()
        }
        else if (sync && sync.Error == true) {
            message.error(sync.message)
            dispatch(GetSyncData())
            form.resetFields()
        }
    }, [sync])

    return (
        <div>
            <Main>
                <PageHeader title={'Sync'} />
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <Cards headless>
                            <ProjectList>
                                <div className='text-right'>
                                    <Button className='mb-20' type='primary' onClick={() => dispatch(GetSyncData())}>Refresh</Button>
                                </div>
                                <div className="table-responsive">
                                    <Table dataSource={syncData} columns={columns} loading={syncDataLoading} />
                                </div>
                            </ProjectList>
                        </Cards>
                    </Col>
                </Row>
            </Main>

            <Modal
                title="Sync FS Passwords"
                open={isModalVisible}
                onCancel={() => { CloseModal() }}
                maskClosable={false}
                centered
                footer={[
                    <Button id="sync-fs-passwords-button" type="primary" onClick={SyncNow}>
                        Sync Now
                    </Button>
                ]}
            >
                <Form id="sync-fs-passwords-form" form={form} layout="vertical">
                    <Form.Item name="days" label="Time" rules={[{ required: true, message: "Please select time" }]} initialValue={1} >
                        <Select id="add-teacher-select-subjects" placeholder="Select time">
                            <Option key={`Last 24hrs`} value={1}>
                                Last 24hrs
                            </Option>
                            <Option key={`Last 48hrs`} value={2}>
                                Last 48hrs
                            </Option>
                            <Option key={`Last 72hrs`} value={3}>
                                Last 72hrs
                            </Option>
                            <Option key={`Last 7days`} value={7}>
                                Last 7days
                            </Option>
                            <Option key={`All`} value={0}>
                                All
                            </Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
