import React, { useEffect, useState } from 'react'
import { Main } from '../styled'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Cards } from '../../components/cards/frame/cards-frame';
import { DataTableStyleWrap } from '../../components/table/Style';
import { NoteCardWrap } from '../note/style';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { getScheduledData, publishInterviewResults } from '../../redux/schedule/actionCreator';
import { Col, Input, Row, Table, Badge, Button } from 'antd';
import { UilSearch } from '@iconscout/react-unicons';

export default function Schedules({ type }) {

    const {
        TopicScheduledData,
        isSchedulesLoading,
        publishResultsRes,
        isSchedulesPublishing
    } = useSelector((state) => {
        return {
            TopicScheduledData: state.TopicScheduledData.data,
            isSchedulesLoading: state.TopicScheduledData.loading,
            publishResultsRes: state.publishResultsRes.data,
            isSchedulesPublishing: state.publishResultsRes.loading
        }
    })

    const dispatch = useDispatch();
    const [selectedRecord, setSelectedRecord] = useState(null)

    useEffect(() => {
        if (dispatch) {
            loadSchedules(type)
        }
    }, [type]);

    const loadSchedules = (type) => {
        dispatch(getScheduledData(type))
    }

    const columnsSort = [
        {
            title: 'S. No.',
            dataIndex: 'sno',
            align: 'center'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            fixed: 'left',
        },
        {
            title: 'Schedule Date',
            dataIndex: 'scheduleDate',
            key: 'scheduleDate',
            sorter: (a, b) => a.scheduleDate > b.scheduleDate ? 1 : -1,
        },
        {
            title: 'Completed on',
            dataIndex: 'completedon',
            sorter: (a, b) => a.completedon > b.completedon ? 1 : -1,
        },
        {
            title: 'Actions',
            key: 'published',
            fixed: 'right',
            render: (text, record) => (
                <div className='table-actions align-center-v'>
                    <Button
                        key="start-stop"
                        type={record.published ? "ghost" : 'primary'}
                        className='mr-10'
                        disabled={(selectedRecord?.id == record.id && isSchedulesPublishing) ?? false}
                        onClick={() => { setSelectedRecord(record); publishSchedule(record.id) }}
                    >
                        {(selectedRecord?.id == record.id && isSchedulesPublishing) ? "Loading" : record.published ? "Hold" : "Publish"}
                    </Button>
                </div>
            )
        },
    ];

    const publishSchedule = (scheduleId) => {
        dispatch(publishInterviewResults(type, scheduleId))
    }

    useEffect(() => {
        if (publishResultsRes) {
            loadSchedules(type)
        }
    }, [publishResultsRes])

    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        if (TopicScheduledData !== null && TopicScheduledData.Error === false && TopicScheduledData.payload.length !== 0) {
            const data = TopicScheduledData.payload.map((obj, index) => {
                const getStatusBadge = (status) => {
                    switch (status) {
                        case "STARTED":
                            return <Badge status="warning" />;
                        case "STOPPED":
                            return <Badge status="error" />;
                        case "COMPLETED":
                            return <Badge status="success" />;
                        default:
                            return <Badge status="default" />;
                    }
                };
                return {
                    sno: index + 1,
                    key: index + 1,
                    id: obj.id,
                    name: obj.name,
                    scheduleDate: obj.scheduleDate,
                    status: obj.status,
                    statusEle: getStatusBadge(obj.status),
                    startedonTime: obj.startedon ? new Date(obj.startedon).toLocaleTimeString() : 'NA',
                    startedon: obj.startedon ? new Date(obj.startedon).toLocaleString() : 'NA',
                    stoppedon: obj.stoppedon ? new Date(obj.stoppedon).toLocaleString() : 'NA',
                    completedon: obj.completedon,
                    published: obj.published
                }
            })

            setTableData(data)
            setFilteredData(data)
        }
        else {
            setTableData([])
            setFilteredData([])
        }
    }, [TopicScheduledData])

    const searchkeys = ['name', 'scheduleDate']
    const searchStudent = (e) => {
        const { value } = e.currentTarget;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    };

    return (
        <div>
            <Main>
                <PageHeader className="ninjadash-page-header-main" title={`${type} Schedules`} />
                {
                    isSchedulesLoading ?
                        <div className="spin">
                            <CustomSpinner Message={'Loading please wait...'} />
                        </div>
                        :
                        <Cards headless>
                            <NoteCardWrap>
                                <DataTableStyleWrap>
                                    <div>
                                        <div className='d-flex justify-content-between mb-15 p-0 align-center-v'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                    <Badge status="success" />
                                                    <span style={{ marginLeft: 8 }}>Completed</span>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                    <Badge status="warning" />
                                                    <span style={{ marginLeft: 8 }}>Started</span>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                                    <Badge status="error" />
                                                    <span style={{ marginLeft: 8 }}>Stopped</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-center-v" style={{ marginLeft: 'auto' }}>
                                                <Input id="monitor_search-input" className='' onChange={searchStudent} size="default" placeholder="Search here..." prefix={<UilSearch />} />
                                            </div>
                                        </div>
                                        <Table
                                            className="table-responsive"
                                            pagination={{
                                                defaultPageSize: 15,
                                                total: filteredData.length,
                                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                            }}
                                            columns={columnsSort}
                                            dataSource={filteredData}
                                            tableLayout='auto'
                                            size='small'
                                            expandable={{
                                                expandedRowRender: (record) =>
                                                    <>
                                                        <div className='p-20'>
                                                            <Row gutter={[16, 16]} justify="space-between">
                                                                <Col xs={24} sm={12}>
                                                                    <div className='mb-10'>
                                                                        <span className=''>Started on</span>: <h4 className='d-inline'>{record.startedon}</h4>
                                                                    </div>
                                                                    <div className='mb-10'>
                                                                        <span className=''>Stopped on</span>: <h4 className='d-inline'>{record.stoppedon}</h4>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </>
                                            }}
                                        />
                                    </div>
                                </DataTableStyleWrap>
                            </NoteCardWrap>
                        </Cards>
                }
            </Main>
        </div>
    )
}
