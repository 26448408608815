import { Modal } from 'antd'
import React, { useState } from 'react'
import ListBlockWithTitle from './ListBlockWithTitle'
import SubListBlock from './SubListBlock'

export default function UserGuideImage({ header, content, imageUrl, list, listType }) {
    const [imgModal, setImageModal] = useState({
        show: false,
        url: ''
    })
    return (
        <div>
            {header && <h2 className='sub-header'>{header}</h2>}
            {
                content &&
                <p className='content'>
                    {content}
                </p>
            }
            {
                list && list.length !== 0 && listType == "headers" ?
                    <SubListBlock data={list} />
                    :
                    list && list.length !== 0 &&
                    <ListBlockWithTitle list={list} />
            }
            <img src={imageUrl} alt='img' className='user-guide-image' onClick={() => setImageModal({ show: true, url: imageUrl })} />
            <Modal
                className='userGuide-modal'
                open={imgModal.show}
                footer={false}
                width={1500}
                centered
                onCancel={() => setImageModal({ show: false, url: '' })}
            >
                <img src={imgModal.url} alt='img' className='user-guide-image-fullscreen' onClick={() => setImageModal({ show: false, url: '' })} />
            </Modal>
        </div>
    )
}
